<template>
  <main id="dev-item">
    <div class="dev__content">
      <div v-html="post.content" />
    </div>
  </main>
</template>

<script>
import wordpress from "@/service/wordpress";

export default {
  name: "test",
  async asyncData({ params, app }) {
    const [post] = await Promise.all([wordpress.get(`page/1254`)])

    return {
      post: post.data
    }
  },
  data() {
    return {
      loggedIn: this.$auth.loggedIn,
      user: this.$auth.loggedIn ? this.$auth.user : {}
    }
  }
}
</script>

<style scoped>

</style>
